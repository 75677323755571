<template>
  <ClientOnly>
    <div
      ref="popover"
      v-on-click-outside="hide"
      :class="['zpopover', cssClass]"
      data-testid="popover-body"
      :data-show="(popperInstance && visible) || undefined"
    >
      <template v-if="visible">
        <slot
          :visible="visible"
          :close="hide"
        />
      </template>
      <div
        v-if="!noArrow"
        class="arrow"
        data-popper-arrow
      />
    </div>
  </ClientOnly>
</template>

<script setup>
import { vOnClickOutside } from '@vueuse/components'
</script>

<script>
import { createPopper } from '@popperjs/core'

export default {
  props: {
    triggerRef: {
      type: [String, Function],
      default: null,
    },

    placement: {
      type: String,
      default: 'bottom',
      validator: (val) => ['bottom', 'top', 'left', 'right', 'bottom-start', 'bottom-end'].includes(val),
    },

    noArrow: {
      type: Boolean,
      default: false,
    },

    cssClass: {
      type: String,
      default: '',
    },
  },

  emits: ['hidden', 'shown'],

  data() {
    return {
      visible: false,
      popperInstance: null,
      myTrigger: null,
    }
  },

  mounted() {
    this.myTrigger = typeof this.triggerRef === 'string' ? this.$parent.$refs[this.triggerRef] : this.triggerRef()
    this.myTrigger.addEventListener('click', () => this.toggleShow())
  },

  beforeUnmount() {
    this.myTrigger?.removeEventListener('click', () => this.toggleShow())
  },

  methods: {
    popper() {
      if (this.popperInstance || !this.myTrigger) {
        return
      }

      this.popperInstance = createPopper(this.myTrigger, this.$refs.popover, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: () => {
                return [0, 16]
              },
            },
          },
        ],
      })
    },

    toggleShow() {
      this.visible = !this.visible

      if (this.visible) {
        this.shown()

        if (this.popperInstance) {
          this.popperInstance.update()
        }
        else {
          this.popper()
        }
      }
      else {
        this.hidden()
      }
    },

    hide(e) {
      if (!e?.composedPath().includes(this.myTrigger)) {
        this.visible = false
        this.hidden()
      }
    },

    hidden() {
      this.$emit('hidden')
    },

    shown() {
      this.$emit('shown')
    },
  },
}
</script>

<style lang="scss" scoped>
.zpopover {
  display: none;
  background-color: #fff;
  padding: 1rem;
  z-index: 15;
  word-wrap: break-word;
  border-radius: 0.5rem;
  border: 1px solid getColor('primary-100');

  &[data-show] {
    display: block;
  }

  .arrow {
    &::before,
    &::after {
      content: '';
      position: absolute;
      transform: rotate(45deg);
      width: 12px;
      height: 12px;
    }

    &::before {
      background-color: getColor('primary-100');
    }

    &::after {
      background-color: #fff;
    }
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: 6px;

    &::before,
    &::after {
      margin-left: -4px;
    }

    &::after {
      margin-top: -1px;
    }
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -6px;

    &::before,
    &::after {
      margin-left: -4px;
    }

    &::after {
      margin-top: 1px;
    }
  }

  &[data-popper-placement^='left'] > .arrow {
    right: 6px;

    &::before,
    &::after {
      margin-top: -4px;
    }

    &::after {
      margin-left: -1px;
    }
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -6px;

    &::before,
    &::after {
      margin-top: -4px;
    }

    &::after {
      margin-left: 1px;
    }
  }
}
</style>
